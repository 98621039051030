/*!
 * Entry point for all the style files of the TruBe SPA
 * Copyright 2023 iWoo Ltd.
 * Licensed under ISC (https://github.com/ronar/master/LICENSE)
 */

// TruBe app styles
@use "variables.scss";
@use "slick.scss";
@use "flatpickr.css";
@use "react-gallery-carousel.scss";
@use "controls.scss";
@use "corporate-page.scss";

.nav-top.tbk-fixed + div {
  padding-top: 64px;
}

.media-block-item img {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.media-block-item.media-block-item-video:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 76px;
  height: 78px;
  transform: translate(-50%, -50%);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzYiIGhlaWdodD0iNzgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZF82MDc5XzIzNzQ3KSI+PHBhdGggZD0iTTUzLjc3MyAzMS40NmMyLjk3IDEuNjg0IDIuOTcgNS4zOTYgMCA3LjA4TDI3Ljc3NiA1My4yNzdDMjQuNDUzIDU1LjE2MyAyMCA1My4xMzYgMjAgNDkuNzRWMjAuMjYxYzAtMy4zOTggNC40NTItNS40MjQgNy43NzYtMy41NGwyNS45OTcgMTQuNzR6IiBmaWxsPSIjZmZmIi8+PC9nPjxkZWZzPjxmaWx0ZXIgaWQ9ImZpbHRlcjBfZF82MDc5XzIzNzQ3IiB4PSIwIiB5PSIwIiB3aWR0aD0iNzYiIGhlaWdodD0iNzgiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj48ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIgcmVzdWx0PSJoYXJkQWxwaGEiLz48ZmVPZmZzZXQgZHk9IjQiLz48ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIxMCIvPjxmZUNvbG9yTWF0cml4IHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4yIDAiLz48ZmVCbGVuZCBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJlZmZlY3QxX2Ryb3BTaGFkb3dfNjA3OV8yMzc0NyIvPjxmZUJsZW5kIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvd182MDc5XzIzNzQ3IiByZXN1bHQ9InNoYXBlIi8+PC9maWx0ZXI+PC9kZWZzPjwvc3ZnPg==);
  z-index: 1;
}

.media-block-item:hover:not(.empty):after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsla(0, 0%, 100%, 0.2);
}
