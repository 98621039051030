.slick-slider {
  //box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
  z-index: 1;
}
.slick-list,
.slick-slider {
  position: relative;
  display: block;
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-slide {
  align-items: center;
  justify-content: center;
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-dots {
  position: absolute;
  bottom: -24px;
}

.slick-dots li {
  display: inline-block;
  //margin-right: 16px;
  font-size: 0;
  line-height: 0;

  button {
    //width: 100%;
    //height: 100%;
    &:after {
      content: "";
      display: block;
      margin: 10px;
      width: 8px; //12px;
      height: 8px; //12px;
      border-radius: 50%;
      background-color: rgb(107, 113, 120); //rgb(203, 207, 213);
    }
  }

  &.slick-active button:after {
    width: 12px; //16px;
    height: 12px; //16px;
    margin: 8px;
    background-color: rgb(255, 117, 87); //rgb(255, 117, 87);
  }
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  height: 32px;
  width: 32px;
  line-height: 0;
  font-size: 0;
  background-color: var(--trube-basic-white);
  color: var(--trube-grey-main);
  padding: 0;
  border-radius: 50%;
  border: none;
  outline: none;
  filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.1));
  z-index: 994; // Less than the header

  &.slick-disabled {
    opacity: 0;
  }

  @media screen and (min-width: 1024px) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

.slick-prev {
  left: -70px;

  [dir="rtl"] & {
    left: auto;
    right: 7px;
  }
}

.slick-next {
  right: -70px;

  [dir="rtl"] & {
    left: 7px;
    right: auto;
  }
}

@media screen and (max-width: 1023px) {
  .slick-prev {
    left: 0;
    bottom: 0;
  }

  .slick-next {
    left: 80px;
    bottom: 0;
  }
}
