/* Stripe Elements */

form .StripeElement {
  height: 48px;
  padding: 10px 1em;
  border-radius: 8px;
  border: 1px solid var(--trube-blue-grey-light, #e0eaf3);

  .form-group.required &,
  &.StripeElement--invalid {
    color: var(--trube-coral, #ff7557);
    border-color: var(--trube-coral, #ff7557);
  }

  &.StripeElement--focus {
    border-color: var(--trube-blue-grey, #72879a);
  }

  &.StripeElement--complete {
    position: relative;
    color: var(--trube-green, #3bc693);

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.5 8L17 9.5L10.5 16L7 12.5L8.5 11L10.5 13L15.5 8Z' fill='%233bc693'%3E%3C/path%3E%3C/svg%3E");
      background-size: 24px;
      background-repeat: no-repeat;
      top: 10px;
      right: 10px;
    }
  }
}
